const sidebars = document.querySelectorAll(".sidebar");

function trapFocus(event, sidebarContent) {
    const elementsInDialog = sidebarContent.querySelectorAll("*");
    const focusableInDialog = Array.from(elementsInDialog).filter(
        (el) => el.tabIndex >= 0
    );
    const firstFocusable = focusableInDialog[0];
    const lastFocusable = focusableInDialog[focusableInDialog.length - 1];

    // Détection de la touche "Escape" pour la fermeture de la fenêtre modale
    if (event.code === "Escape") {
        sidebarContent.classList.remove("active");
        document.body.classList.remove("inert");
    }

    // Restriction du parcours de tabulation à l'intérieur de la fenêtre modale...
    if (event.code === "Tab") {
        if (!sidebarContent.contains(event.target) && event.shiftKey) {
            // ...à la tabulation arrière
            lastFocusable.focus();
        } else if (!sidebarContent.contains(event.target)) {
            // ...à la tabulation avant
            firstFocusable.focus();
        }
    }
}

function showSidebar(sidebarContent) {
    sidebarContent.classList.add("active");
    document.body.classList.add("inert");
}

function hideSidebar(sidebarContent) {
    sidebarContent.classList.remove("active");
    document.body.classList.remove("inert");

    const key = sidebarContent.dataset.sidebarContent;
    if (key === 'filters-sidebar') {
        Livewire.dispatch('filters-sidebar-closed');
    }
}

function toggleSidebar(sidebar, triggerButton) {
    const sidebarContent = sidebar.querySelector("[data-sidebar-content]");

    if (sidebarContent.classList.contains("active")) {
        hideSidebar(sidebarContent);
        if(triggerButton!=undefined)
            triggerButton?.focus();
        document.removeEventListener("keyup", (event) =>
            trapFocus(event, sidebarContent)
        );
    } else {
        const elementsInDialog = sidebarContent.querySelectorAll("*");
        const focusableInDialog = Array.from(elementsInDialog).filter(
            (el) => el.tabIndex >= 0
        );
        const firstFocusable = focusableInDialog[0];
        showSidebar(sidebarContent);

        firstFocusable.focus();

        document.addEventListener("keyup", (event) =>
            trapFocus(event, sidebarContent)
        );
    }
}

sidebars.forEach((sidebar) => {
    const triggerButtons = document.querySelectorAll(
        `[data-sidebar-trigger="${sidebar.getAttribute("id")}"]`
    );
    const closeButton = sidebar.querySelector(".sidebar__close");
    const sidebarBackdrop = sidebar.querySelector(".sidebar-content-backdrop");

    if (closeButton && sidebarBackdrop && triggerButtons) {
        triggerButtons.forEach((triggerButton) => {
            triggerButton.addEventListener("click", () =>
                toggleSidebar(sidebar, triggerButton)
            );
        });

        closeButton.addEventListener("click", () => toggleSidebar(sidebar));
        sidebarBackdrop.addEventListener("click", () => toggleSidebar(sidebar));
    }
});

document.addEventListener("livewire:init", () => {
    Livewire.on("toggle-sidebar", (event) => {
        const sidebar = document.getElementById(event.value);
        const triggerButton = document.querySelectorAll(
            `[data-sidebar-trigger="${event.value}"]`
        );
        toggleSidebar(sidebar, triggerButton);
    });
});
